<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/contentConfig/autismSpeaks"
            >自闭症之声 /
          </router-link>
          <span class="crumbs_item crumbs_last">{{
            operation == 1 ? "新建" : "编辑"
          }}</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <div class="all_left_name all_required">书名</div>
        <div>
          <a-input
            class="all_input all_margin-r"
            placeholder="请输入名称"
            v-model="data.name"
          />
        </div>
      </div>
      <a-row>
        <!-- <a-col :span="8">
          <div class="Up_Down_inner">
            <span class="all_left_name all_required">移动端封面</span>
            <span class="all_left_name redtext">建议尺寸：194*251px</span>
            <div>
              <a-upload
                list-type="picture-card"
                :showUploadList="false"
                :beforeUpload="beforeUpload"
                :customRequest="customRequest"
              >
                <img
                  v-if="data.mobileImg"
                  :src="data.mobileImg"
                  alt="avatar"
                  class="photo"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-col> -->
        <a-col :span="8">
          <div class="Up_Down_inner">
            <span class="all_left_name all_required">封面</span>
            <span class="all_left_name redtext">建议尺寸：206*263px</span>
            <div>
              <a-upload
                list-type="picture-card"
                :showUploadList="false"
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
                :beforeUpload="beforeUpload"
                :customRequest="customRequest2"
              >
                <img
                  v-if="data.img"
                  :src="data.img"
                  alt="avatar"
                  class="photo"
                />
                <div v-else>
                  <a-icon :type="loading2 ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-col>
      </a-row>
      <!-- <div class="Up_Down_inner">
        <div class="all_left_name all_required">作者</div>
        <div>
          <a-input
            class="all_input all_margin-r"
            placeholder="请输入作者名称"
            v-model="data.author"
          />
        </div>
      </div>
      <div class="Up_Down_inner">
        <div class="all_left_name all_required">译者</div>
        <div>
          <a-input
            class="all_input all_margin-r"
            placeholder="请输入译者名称"
            v-model="data.translator"
          />
        </div>
      </div> -->
      <div class="L_R_inner">
        <span class="all_left_name all_required">文件</span>
        <div class="right_Div">
          <a-upload
            :showUploadList="false"
            :customRequest="customRequest3"
            :beforeUpload="beforeUpload3"
            accept=".pdf"
          >
            <a-button> <a-icon type="upload" />上传文件</a-button>
          </a-upload>
        </div>
        <span class="align">仅限pdf格式</span>
      </div>
      <div class="audioname">
        <a class="videoName" target="_blank" :href="data.fileUrl">{{ data.fileUrl }}</a>
      </div>
      <!-- <div class="align">仅限pdf格式</div> -->
      <div class="L_R_inner" v-show="operation == 2">
        <span class="all_left_name all_required">所属分类</span>
        <div class="right_Div">
          <a-select style="width:300px" v-model="autismClassificationId" placeholder="请选择分类状态" allowClear>
            <a-icon slot="suffixIcon" type="caret-down" />
            <a-select-option v-for="(item,index) in classList" :key="index" :value="item.autismClassificationId">{{item.name}}</a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <div class="button">
      <a-button type="primary" class="btn" :loading="loading4" @click="onSave()"
        >确认</a-button
      >
      <a-button class="all_boder_btn btn" @click="$router.go(-1)"
        >取消</a-button
      >
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {}, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      file: {},
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      headers: {
        authorization: "authorization-text",
      },
      data: {
        name: "",
        // mobileImg: "",
        img: "",
        author: "",
        translator: "",
        fileUrl: "",
        autismId: "",
        autismClassificationId: this.$route.query.classId
      },
      autismClassificationId: undefined,
      classList: []
    };
  }, // 事件处理器
  methods: {
    // 上传前钩子上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
    },
    beforeUpload3(file) {
      if (file.type != "application/pdf") {
        this.$message.warning("请上传pdf格式文件");
        return false;
      }
    },
    // 文件上传
    customRequest(fileData) {
      this.loading = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.data.mobileImg = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },
    // 文件上传
    customRequest2(fileData) {
      this.loading2 = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.data.img = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading2 = false;
      });
    },

    // 文件上传
    customRequest3(fileData) {
      this.loading3 = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.data.fileUrl = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading3 = false;
      });
    },

    

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    // 分类
    getData() {
      this.$ajax({
        url: "/hxclass-management/autism/classification/list",
        method: "get",
        params: {
          pageNum: 1,
          pageSize: 100,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.classList = res.data.records 
        }
      });
    },

    // 查询详情
    getDetail() {
      this.loading4 = true;
      this.$ajax({
        url: "/hxclass-management/autism/details",
        params: {
          autismId: this.id,
        },
      }).then((res) => {
        this.loading4 = false;
        if (res.code == 200 && res.success) {
          this.data = res.data;
          this.autismClassificationId = parseInt(this.$route.query.classId);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    onSave() {
      if (this.operation == 2) {
        this.data.autismId = this.id;
        // console.log(this.data.autismId);
      }
      if (!this.data.name) {
        this.$message.warning("请填写书名");
        return;
      }
      // if (!this.data.author) {
      //   this.$message.warning("请输入作者名称");
      //   return;
      // }
      // if (!this.data.translator) {
      //   this.$message.warning("请输入译者名称");
      //   return;
      // }
      // if (!this.data.mobileImg) {
      //   this.$message.warning("请上传h5图片");
      //   return;
      // }
      if (!this.data.img) {
        this.$message.warning("请上传封面");
        return;
      }
      if (!this.data.fileUrl) {
        this.$message.warning("请上传pdf文件");
        return;
      }

      var url = "";
      var method = "";
      this.loading4 = true;
      if (this.data.autismId) {
        this.data.autismClassificationId = this.autismClassificationId
        // 编辑
        url = "/hxclass-management/autism/add";
        method = "put";
      } else {
        // 新增
        url = "/hxclass-management/autism/add";
        method = "post";
      }
      this.$ajax({
        url: url,
        method: method,
        params: this.data,
      }).then((res) => {
        this.loading4 = false;
        if (res.code == 200 && res.success) {
          this.$router.push("/admin/ContentConfig/autismSpeaks");
        } else {
          this.$message.error(res.message);
        }
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    this.id = this.$route.query.id;
    this.operation = this.$route.query.operation;
    if (this.operation == 2) {
      this.getDetail();
      this.getData()
    }
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>


<style lang="less" scoped>
.all_main_wrap {
  a {
    color: #333333;
  }
  .redtext {
    margin-left: 14px;
    font-size: 12px;
    color: #ff4444;
  }
  .align {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff0000;
    line-height: 42px;
    margin-left: 24px;
  }
  .all_content_box {
    .vue-ueditor-wrap[data-v-1e1388a0] {
      width: 800px;
    }
    .L_R_inner {
      margin-top: 24px;
    }
    .Up_Down_inner {
      margin-top: 24px;
      display: block;
      textarea.ant-input,
      .all_input {
        width: 525px;
      }
      textarea.ant-input {
        height: 192px;
      }
      .ant-calendar-picker,
      .input {
        width: 397px;
      }
      .short {
        width: 197px;
      }
      .photo {
        max-width: 150px;
      }
    }
  }

  .button {
    .btn {
      margin: 36px 14px;
    }
  }

  .audioname {
    margin-top: 20px;

    .videoName {
      font-style: normal;
      color: @theme;
      font-size: 14px;
      margin-left: 5px;
    }
  }
}
</style>